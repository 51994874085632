import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Service Spotlight: Mobile In-house Fiberglass Repair"
            description="While fiberglass holds up well, it can reach its limit and when it does, it doesn’t bend, it breaks. Experienced fiberglass repair specialists can help."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="Service Spotlight: Mobile In-house Fiberglass Repair"
            date="October 14, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     The introduction of fiberglass into the recreational and automotive industries, to name a couple, was undoubtedly a revolutionary
                     change. And while fiberglass holds up well to most forms of use and abuse, it doesn’t bend when it reaches its limit. It breaks.
                     When this happens, you need experienced fiberglass repair professionals to restore the damage to a like-new condition. Our team
                     at Painted Rhino is happy to help.
                  </p>
                  <h2>What Our Fiberglass Professionals Can Do</h2>
                  <p>
                     When it comes to fiberglass, the possibilities are endless when you work with the Painted Rhino team. We can fix RVs, vehicles,
                     boats, golf carts, windmills, buses, and much more. Here are some of the repair tasks you can count on our experts to handle:
                  </p>
                  <ul>
                     <li>
                        <b>Cosmetic Damages:</b> So, it finally happened. You backed your camper into a spot too close to a tree and paid the price in
                        a horrible scrape down the side. However, the cosmetic damage happens, we can restore your camper to a like-new condition with
                        ease.
                     </li>
                     <li>
                        <b>Collision Repair:</b> If your vehicle was damaged in a collision, we’ll repair the cracks or holes. We take structural
                        integrity and the existing electrical and plumbing work into consideration when handling this.
                     </li>
                     <li>
                        <b>Reconstruction:</b> Sometimes, the damage is so extensive that you need an entire reconstruction. There’s no job too big or
                        too complex for our team. Whatever the project you have in mind, we’re prepared to take on the challenge.
                     </li>
                  </ul>
                  <h2>We Offer Both In-House And Mobile Fiberglass Repair</h2>
                  <p>
                     When it comes to service, we take a client-first approach. We mean it when we say your needs come first and we do everything
                     possible to help you materialize your fiberglass vision — even when it transcends regular repairs. With this at the forefront of
                     our business strategy, we provide both in-house and mobile repair services, so you can choose the option that’s right for your
                     needs.
                  </p>
                  <h3>When To Consider In-House Repairs</h3>
                  <p>
                     The more extensive the repair job, the more beneficial it would be to complete the work in-house. Reconstruction and collision
                     jobs tend to fall into this category. In-house work is also convenient for customers who would rather not have the damaged
                     vehicle or a team of workmen on their property.
                  </p>
                  <h3>When To Consider Mobile Repairs</h3>
                  <p>
                     Mobile repairs are, without a doubt, the most convenient repair service. We recommend going mobile for situations where you may
                     have a busy schedule or feel it may be best not to transport the item in need of repair. In addition, our clients commonly choose
                     mobile repair services for less mobile vehicles, such as rollercoaster cars (yes, we do those too!).
                  </p>
                  <h2>Why Choose Painted Rhino For Fiberglass Repair?</h2>
                  <p>
                     When it comes to restoring your investment, we’re one of the most trusted companies you can turn to. We’re a family-owned
                     business, and we know the manufacturing and repair process like the backs of our hands. That’s because we’ve been working in the
                     industry for over 25 years. This makes it easy for us to troubleshoot problems that occur and innovate quickly to find creative
                     solutions.
                  </p>
                  <p>
                     Are you ready to get started? We are. <a data-modal-open="modal-contact">Contact us</a> for a fiberglass repair quote today.
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/Service-mobile-&-in-house-fiberglass.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 833) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
